import { Link } from 'gatsby';
import React from 'react';
import Container from '../../components/layout/Container/Container';
import PageLayout from '../../components/layout/PageLayout/PageLayout';
import PageSection from '../../components/layout/PageSection/PageSection';
import Seo from '../../components/utils/Seo';

// mdx render from yaml works only with gatsby-transformer-yaml-full but I changed that is it doesn't support images
const PrivacyPolicy = () => {
    return (
        <PageLayout>
            <Seo
                isPost={false}
                title="Privacy Policy"
                url="privacy_policy/"
                description="Privacy policy for AI Summer"
            />
            <PageSection>
                <Container>
                    <h1 id="privacy-policy-for-ai-summer">
                        Privacy Policy for AI Summer
                    </h1>

                    <p>
                        At AI Summer, accessible at theaisummer.com (hereinafter
                        known as “Provider,” “us” or “we” or “the Site”), one of
                        our main priorities is the privacy of our visitors. This
                        Privacy Policy document contains types of information
                        that is collected and recorded by us and how we use it.
                    </p>

                    <p>
                        We are committed to protecting your privacy and
                        committed to developing technology that gives you the
                        most powerful and secure online experience.
                    </p>

                    <p>
                        If you have additional questions or require more
                        information about our Privacy Policy, do not hesitate to{' '}
                        <Link to="contact/">contact us through email </Link>
                    </p>

                    <p>
                        This privacy policy applies only to our online
                        activities and is valid for visitors to our website with
                        regards to the information that they shared and/or
                        collect in theaisummer.com. This policy is not
                        applicable to any information collected offline or via
                        channels other than this website.
                    </p>

                    <h2 id="consent">Consent</h2>

                    <p>
                        By using our website, you hereby consent to our Privacy
                        Policy and agree to its terms.
                    </p>

                    <h2 id="information-we-collect">Information we collect</h2>

                    <p>
                        The personal information that you are asked to provide,
                        and the reasons why you are asked to provide it, will be
                        made clear to you at the point we ask you to provide
                        your personal information. If you contact us directly,
                        we may receive additional information about you such as
                        your name, email address, phone number, the contents of
                        the message and/or attachments you may send us, and any
                        other information you may choose to provide.
                    </p>

                    <h2 id="how-we-use-your-information">
                        How we use your information
                    </h2>

                    <p>
                        We use the information we collect in various ways,
                        including to:
                    </p>

                    <ul>
                        <li>
                            <p>Provide, operate, and maintain our website</p>
                        </li>
                        <li>
                            <p>Improve, personalize, and expand our website</p>
                        </li>
                        <li>
                            <p>
                                Understand and analyze how you use our website
                            </p>
                        </li>
                        <li>
                            <p>
                                Develop new products, services, features, and
                                functionality
                            </p>
                        </li>
                        <li>
                            <p>
                                Communicate with you, either directly or through
                                one of our partners, including for customer
                                service, to provide you with updates and other
                                information relating to the webste, and for
                                marketing and promotional purposes
                            </p>
                        </li>
                        <li>
                            <p>Send you emails</p>
                        </li>
                        <li>
                            <p>Find and prevent fraud</p>
                        </li>
                    </ul>

                    <h2 id="log-files">Log Files</h2>

                    <p>
                        We follow a standard procedure of using log files. These
                        files log visitors when they visit websites. All hosting
                        companies do this and a part of hosting services’
                        analytics. The information collected by log files
                        include internet protocol (IP) addresses, browser type,
                        Internet Service Provider (ISP), date and time stamp,
                        referring/exit pages, and possibly the number of clicks.
                        These are not linked to any information that is
                        personally identifiable. The purpose of the information
                        is for analyzing trends, administering the site,
                        tracking users’ movement on the website, and gathering
                        demographic information.
                    </p>

                    <h2 id="cookies-and-web-beacons">
                        Cookies and Web Beacons
                    </h2>

                    <p>
                        Like any other website, theaisummer.com uses ‘cookies’.
                        These cookies are used to store information including
                        visitors’ preferences, and the pages on the website that
                        the visitor accessed or visited. The information is used
                        to optimize the users’ experience by customizing our web
                        page content based on visitors’ browser type and/or
                        other information.
                    </p>

                    <h2 id="advertising-partners-privacy-policies">
                        Advertising Partners Privacy Policies
                    </h2>

                    <p>
                        Third-party ad servers or ad networks uses technologies
                        like cookies, JavaScript, or Web Beacons that are used
                        in their respective advertisements and links that appear
                        on theaisummer.com, which are sent directly to users’
                        browser. They automatically receive your IP address when
                        this occurs. These technologies are used to measure the
                        effectiveness of their advertising campaigns and/or to
                        personalize the advertising content that you see on
                        websites that you visit.
                    </p>

                    <p>
                        Note that we have no access to or control over these
                        cookies that are used by third-party advertisers.
                    </p>

                    <h2 id="third-party-privacy-policies">
                        Third-Party Privacy Policies
                    </h2>

                    <p>
                        theaisummer.com’s Privacy Policy does not apply to other
                        advertisers or websites. Thus, we are advising you to
                        consult the respective Privacy Policies of these
                        third-party ad servers for more detailed information. It
                        may include their practices and instructions about how
                        to opt-out of certain options. You may find a complete
                        list of these Privacy Policies and their links here:
                        Privacy Policy Links. You can choose to disable cookies
                        through your individual browser options. To know more
                        detailed information about cookie management with
                        specific web browsers, it can be found at the browsers’
                        respective websites.
                    </p>

                    <h2 id="affiliate-disclosure">Affiliate Disclosure</h2>

                    <p>
                        This site uses affiliate links and does earn a
                        commission from certain links. This does not affect your
                        purchases or the price you may pay. Please note that we
                        only suggest products that we have experience with and
                        we find useful and helpful.
                    </p>

                    <p>
                        We are also a participant in the Amazon Services LLC
                        Associates Program, an affiliate advertising program
                        designed to provide a means for sites to earn
                        advertising fees by advertising and linking to
                        amazon.com.
                    </p>

                    <h2 id="links-to-external-sites">
                        Links To External Sites
                    </h2>

                    <p>
                        Our Service may contain links to external sites that are
                        not operated by us. If you click on a third party link,
                        you will be directed to that third party’s site. We
                        strongly advise you to review the Privacy Policy and
                        terms and conditions of every site you visit. We have no
                        control over, and assume no responsibility for the
                        content, privacy policies or practices of any third
                        party sites, products or services.
                    </p>

                    <h2 id="ccpa-privacy-policy-do-not-sell-my-personal-information">
                        CCPA Privacy Policy (Do Not Sell My Personal
                        Information)
                    </h2>

                    <p>
                        Under the CCPA, among other rights, California consumers
                        have the right to:
                    </p>

                    <p>
                        Request that a business that collects a consumer’s
                        personal data disclose the categories and specific
                        pieces of personal data that a business has collected
                        about consumers.
                    </p>

                    <p>
                        Request that a business delete any personal data about
                        the consumer that a business has collected.
                    </p>

                    <p>
                        Request that a business that sells a consumer’s personal
                        data, not sell the consumer’s personal data.
                    </p>

                    <p>
                        If you make a request, we have one month to respond to
                        you. If you would like to exercise any of these rights,
                        please contact us.
                    </p>

                    <h2 id="gdpr-privacy-policy-data-protection-rights">
                        GDPR Privacy Policy (Data Protection Rights)
                    </h2>

                    <p>
                        We would like to make sure you are fully aware of all of
                        your data protection rights. Every user is entitled to
                        the following:
                    </p>

                    <p>
                        The right to access – You have the right to request
                        copies of your personal data. We may charge you a small
                        fee for this service.
                    </p>

                    <p>
                        The right to rectification – You have the right to
                        request that we correct any information you believe is
                        inaccurate. You also have the right to request that we
                        complete the information you believe is incomplete. The
                        right to erasure – You have the right to request that we
                        erase your personal data, under certain conditions.
                    </p>

                    <p>
                        The right to restrict processing – You have the right to
                        request that we restrict the processing of your personal
                        data, under certain conditions.
                    </p>

                    <p>
                        The right to object to processing – You have the right
                        to object to our processing of your personal data, under
                        certain conditions.
                    </p>

                    <p>
                        The right to data portability – You have the right to
                        request that we transfer the data that we have collected
                        to another organization, or directly to you, under
                        certain conditions.
                    </p>

                    <p>
                        If you make a request, we have one month to respond to
                        you. If you would like to exercise any of these rights,
                        please contact us.
                    </p>

                    <h2 id="childrens-information">Children’s Information</h2>

                    <p>
                        Another part of our priority is adding protection for
                        children while using the internet. We encourage parents
                        and guardians to observe, participate in, and/or monitor
                        and guide their online activity. theaisummer.com does
                        not knowingly collect any Personal Identifiable
                        Information from children under the age of 13. If you
                        think that your child provided this kind of information
                        on our website, we strongly encourage you to contact us
                        immediately and we will do our best efforts to promptly
                        remove such information from our records.
                    </p>
                </Container>
            </PageSection>
        </PageLayout>
    );
};

export default PrivacyPolicy;
